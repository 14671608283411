import * as echarts from 'echarts';
// import i18n from "@/i18n"

function showTooltip(myChart, dataIndex) {
	myChart.dispatchAction({
		type: 'showTip',
		seriesIndex: 0,
		dataIndex: dataIndex
	});
}

function hideTooltip(myChart, dataIndex) {
	myChart.dispatchAction({
		type: 'hideTip',
		seriesIndex: 0,
		dataIndex: dataIndex
	});
}

const symbolSize = 10;
let maxNumber = 1
let minNumber = -1
/* point 上｜左｜下｜右 */
let data = [
	[0, maxNumber],
	[minNumber, 0],
	[0, minNumber],
	[maxNumber, 0]
];
let series;


function updateChartData(options, reset) {
	series.push({
		type: 'radar',
		symbolSize: 0,
		data: [{
			// TODO:
			value: options.detailData,
			name: options.name,
			label: {
				show: false,
				formatter: (params) => {
					return params.value;
				},
				color: '#000'
			},
			areaStyle: {
				color: options.color,
				opacity: 0.5
			},
			lineStyle: {
				width: 1,
				color: options.color
			}
		}]
	})

	let myChart = options.chartDom && echarts.init(options.chartDom);
	setTimeout(function () {
		if (reset) {
			data = [
				[0, maxNumber],
				[minNumber, 0],
				[0, minNumber],
				[maxNumber, 0]
			];
			initInvisibleGraphic(myChart, data, options.chartData, 10, options.color, options.type, options.width, options.height, options.name, options.calback)
		}
		initInvisibleGraphic(myChart, data, options.chartData, 10, options.color, options.type, options.width, options.height, options.name, options.calback)
	}, 0);

}

function onPointDragging(myChart, data, data1, color, width, height, name, dataIndex, pos, position, e) {
	let positionX = myChart.convertFromPixel('grid', pos)[0]
	let positionY = myChart.convertFromPixel('grid', pos)[1]
	/* Y轴操作 */
	if (dataIndex == 0 || dataIndex == 2) {
		if (positionY >= minNumber && positionY <= maxNumber) {
			data[dataIndex][1] = positionY
			if (dataIndex == 0) {
				data1[dataIndex] = Number(positionY.toFixed(1))
			} else {
				data1[dataIndex] = Number(-(positionY.toFixed(1)))
			}
		} else if (positionY == minNumber) {
			data[dataIndex][1] = minNumber
			data1[dataIndex] = maxNumber
		} else if (positionY == maxNumber) {
			data[dataIndex][1] = maxNumber
			data1[dataIndex] = maxNumber
		}
		/* 控制移动y轴的范围 */
		e.target.x = width / 2
		const top = position[1]
		if (dataIndex == 0) {
			if (e.target.y < top) {
				e.target.y = top
			} else if (e.target.y >= height / 2) {
				e.target.y = height / 2
			}
		} else {
			if (e.target.y < height / 2) {
				e.target.y = height / 2
			} else if (e.target.y >= top) {
				e.target.y = top
			}
		}
	}
	/* X轴操作 */
	if (dataIndex == 1 || dataIndex == 3) {
		if (positionX >= minNumber && positionX <= maxNumber) {
			data[dataIndex][0] = positionX
			if (dataIndex == 3) {
				data1[dataIndex] = Number(positionX.toFixed(1))
			} else {
				data1[dataIndex] = Number(-(positionX.toFixed(1)))
			}
		} else if (positionX == minNumber) {
			data[dataIndex][0] = minNumber
			data1[dataIndex] = maxNumber
		} else if (positionX == maxNumber) {
			data[dataIndex][0] = maxNumber
			data1[dataIndex] = maxNumber
		}
		/* 控制移动x轴的范围 */
		e.target.y = height / 2
		const left = position[0]
		if (dataIndex == 1) {
			if (e.target.x < left) {
				e.target.x = left
			} else if (e.target.x >= width / 2) {
				e.target.x = width / 2
			}
		} else {
			if (e.target.x < width / 2) {
				e.target.x = width / 2
			} else if (e.target.x >= left) {
				e.target.x = left
			}
		}
	}

	// Update data

	myChart.setOption({
		series: [{
			type: 'line',
			smooth: true,
			lineStyle: {
				width: 0
			},
			symbolSize: 0,
			data: data
		},
		{
			type: 'radar',
			symbolSize: 0,
			data: [{
				// TODO:
				value: data1,
				name: name,
				label: {
					show: false,
					formatter: (params) => {
						return params.value;
					},
					color: '#000'
				},
				lineStyle: {
					type: 'dashed',
					width: 1,
					color: '#000'
				}
			}]
		}
		]
	});
}

function initInvisibleGraphic(myChart, data, data1, symbolSize, color, type, width, height, name, calback) {
	if (myChart) {
		myChart.setOption({
			graphic: data.map(function (item, dataIndex) {
				let position = myChart.convertToPixel('grid', item)
				return {
					type: 'circle',
					position: position,
					shape: {
						cx: 0,
						cy: 0,
						r: symbolSize / 2
					},
					style: {
						fill: "#fff",
						stroke: '#000'
					},
					invisible: false,
					draggable: true,
					ondrag: function (e) {
						// console.log('xy: ' + this.x + ', ' + this.y)
						onPointDragging(myChart, data, data1, color, width, height, name, dataIndex, [this.x,
						this.y
						], position, e);
					},
					ondragend: () => {
						/* 结束操作后｜回调数据 */
						calback && calback({
							type: type,
							data: data1
						})
					},
					onmousemove: function () {
						showTooltip(myChart, dataIndex);
					},
					onmouseout: function () {
						hideTooltip(myChart, dataIndex);
					},
					z: 100
				};
			})
		});
	}
}

const dragRadarChart = (options, calback, reset) => {
	let {
		indicator,
		chartDom,
		chartData,
		type,
		width,
		height,
		color,
		name,
	} = options;

	let myChart = chartDom && echarts.init(chartDom);
	myChart.clear()
	const data1 = chartData
	data = [
		[0, maxNumber],
		[minNumber, 0],
		[0, minNumber],
		[maxNumber, 0]
	];
	color = options.color
	series = [
		{
			type: 'line',
			smooth: true,
			lineStyle: {
				width: 0,
			},
			symbolSize: 0,
			data: data
		},
		{
			type: 'radar',
			symbolSize: 0,
			data: [{
				// TODO:
				value: data1,
				name: name,
				label: {
					show: false,
					formatter: (params) => {
						return params.value;
					},
					color: '#000'
				},
				lineStyle: {
					type: 'dashed',
					width: 1,
					color: '#000',
				}
			}]
		}
	];
	if (options.detailData) {
		updateChartData(options, reset)
	}

	let option = {
		tooltip: {
			// triggerOn: 'none',
			trigger: 'item',
		},
		grid: {
			top: '12%',
			bottom: '12%',
			right: '28%',
			left: '28%'
		},
		xAxis: {
			min: minNumber,
			max: maxNumber,
			type: 'value',
			show: false,
			axisLine: {
				onZero: true
			}
		},
		yAxis: {
			min: minNumber,
			max: maxNumber,
			type: 'value',
			show: false,
			axisLine: {
				onZero: true
			}
		},
		radar: {
			axisName: {
				color: '#A8A8A8',
				fontSize: 12,
				//指示器文字换行 start
				formatter: function (text) {
					text = text.replace(' ', '\n')
					return text;
				}
				//指示器文字换行 end
			},
			indicator: indicator
		},
		color: '#000',
		series: series
	};
	setTimeout(function () {
		// Add shadow circles (which is not visible) to enable drag.
		if (myChart) {
			if (reset) {
				data = [
					[0, maxNumber],
					[minNumber, 0],
					[0, minNumber],
					[maxNumber, 0]
				];
				initInvisibleGraphic(myChart, data, data1, symbolSize, color, type, width, height, name, calback)
			}
			initInvisibleGraphic(myChart, data, data1, symbolSize, color, type, width, height, name, calback)
		}

	}, 0);

	myChart && option && myChart.setOption(option);
	return myChart
}

export {
	dragRadarChart,
	initInvisibleGraphic,
}
