<template>
  <div class="search">
    <!-- 按钮 -->
    <div class="d-flex justify-content-between mb-3">
      <button
        v-permit="'fabric.add'"
        type="button"
        class="btn btn-success me-3"
        @click="addClick()"
      >
        <i class="ri-add-fill me-1 align-bottom"></i>
        {{ $t("fabric.add") }}
      </button>
      <div>
        <button
          class="btn btn-primary"
          type="button"
          aria-expanded="false"
          data-bs-toggle="collapse"
          data-bs-target=".search-collapse"
          aria-controls="searchCollapse1 searchCollapse2"
          @click="collapseClick"
        >
          <i class="ri-equalizer-fill me-1 align-bottom"></i>
          {{ $t("base.advance_search") }}
        </button>
      </div>
    </div>

    <!-- 关键字搜索 -->
    <div>
      <div class="collapse search-collapse show" id="searchCollapse1">
        <div class="d-flex justify-content-between">
          <div class="flex-1 me-3">
            <div class="search-box" style="margin: auto!important;width: auto!important;">
              <input
                type="text"
                class="form-control"
                :placeholder="$t('fabric.keyword_placeholder')"
                v-model="keyword"
              />
              <i class="ri-search-line search-icon"></i>
            </div>
          </div>
          <div class="">
            <button
              type="button"
              class="btn btn-primary me-3"
              @click="searchClick()"
            >
              <i class="ri-search-line me-1 align-bottom"></i>
              {{ $t("base.search") }}
            </button>
            <button type="button" class="btn btn-info" @click="resetClick()">
              <i class="ri-reply-line me-1 align-bottom"></i>
              {{ $t("base.reset") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 高级搜索 -->
    <b-row class="collapse search-collapse" id="searchCollapse2">
      <b-col>
        <b-row>
          <b-col :cols="cols">
            <label for="weight-field" class="form-label">
              <span class="text-danger">*</span>
              <span>{{ $t("attribute.code") }}</span>
            </label>
            <Multiselect
              class="form-control"
              v-model="select_code"
              label="title"
              value-prop="code"
              :close-on-select="true"
              :searchable="true"
              :create-option="false"
              :options="search_list.code"
              @input="handlerSelect('code')"
              :class="{
                'is-invalid':
                  highLevelSearch && submitted && v$.select_code.$error,
              }"
              required
            />
            <div
              v-for="(item, index) in v$.select_code.$errors"
              :key="index"
              class="invalid-feedback position-absolute"
            >
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </b-col>
          <b-col :cols="cols">
            <label class="form-label">{{ $t("fabric.structure") }}</label>
            <Multiselect
              id="structure"
              class="form-control"
              v-model="search_structure"
              :mode="select_mode_structure"
              :close-on-select="true"
              :searchable="true"
              :create-option="false"
              :options="search_list.structure"
              @input="handlerSelect('structure')"
            />
          </b-col>
          <b-col :cols="cols">
            <label class="form-label">{{ $t("fabric.thickness") }}</label>
            <InputNumberRange
              ref="thickness"
              type="thickness"
              @numberChange="inputNumberChange"
            >
            </InputNumberRange>
          </b-col>
          <b-col :cols="cols">
            <label class="form-label">{{
              $t("fabric.weight") + " (" + $t("fabric.weight_unit") + ")"
            }}</label>
            <InputNumberRange
              ref="weight"
              type="weight"
              @numberChange="inputNumberChange"
            >
            </InputNumberRange>
          </b-col>
          <b-col :cols="cols" class="mt-3">
            <label class="form-label">{{
              $t("fabric.density") + " (" + $t("fabric.density_unit") + ")"
            }}</label>
            <InputNumberRange
              ref="density"
              type="density"
              @numberChange="inputNumberChange"
            >
            </InputNumberRange>
          </b-col>
          <b-col :cols="cols" class="mt-3">
            <label class="form-label">{{ $t("fabric.tortuous") }}</label>
            <!-- <input type="text" class="form-control" v-model="search_tortuous" /> -->
            <InputNumberRange
              ref="tortuous"
              type="tortuous"
              @numberChange="inputNumberChange"
            >
            </InputNumberRange>
          </b-col>
          <b-col :cols="cols" class="mt-3">
            <label class="form-label">{{
              $t("attribute.cost") + " (" + $t("attribute.price_unit") + ")"
            }}</label>
            <InputNumberRange
              ref="cost"
              type="cost"
              @numberChange="inputNumberChange"
            ></InputNumberRange>
          </b-col>
          <b-col :cols="cols" class="mt-3">
            <label class="form-label">{{
              $t("attribute.stock") + " (" + $t("attribute.stock_unit") + ")"
            }}</label>
            <InputNumberRange
              ref="stock"
              type="stock"
              @numberChange="inputNumberChange"
            ></InputNumberRange>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="auto">
        <div
          class="row"
          style="position: relative; top: 100%; transform: translateY(-100%)"
        >
          <div>
            <div class="col-auto">
              <button
                type="button"
                class="btn btn-primary me-3"
                @click="searchClick()"
              >
                <i class="ri-search-line me-1 align-bottom"></i>
                {{ $t("base.search") }}
              </button>
              <button type="button" class="btn btn-info" @click="resetClick()">
                <i class="ri-reply-line me-1 align-bottom"></i>
                {{ $t("base.reset") }}
              </button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="!searchVisiable"
      ><SearchChart
        ref="searchChart"
        v-model:chartData="chartData"
        v-model:resetChart="resetChart"
        :chooseTableInfo="chooseTableInfo"
        :handlerClickTable="handlerClickTable"
        @updateFunction="updateFunction"
      ></SearchChart
    ></b-row>
  </div>
</template>

<script>
import InputNumberRange from "@/views/components/InputNumberRange";
import SearchChart from "@/views/components/SearchChartFabric";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import i18n from "@/i18n";
import _ from "lodash";
export default {
  components: {
    InputNumberRange,
    SearchChart,
  },
  emits: [
    "update:searchInfo",
    "initFabric",
    "update:highLevelSearch",
    "update:showCheckbox",
    "update:page",
  ],
  props: {
    searchInfo: {
      type: Array,
      default: () => [],
    },
    search_list: {
      type: Object,
      default: () => {},
    },
    handlerClickTable: {
      type: Boolean,
      default: false,
    },
    chooseTableInfo: {
      type: Object,
      default: () => {},
    },
    highLevelSearch: {
      type: Boolean,
      default: false,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations: {
    select_code: {
      required: helpers.withMessage(i18n.global.t("base.hint_code"), required),
    },
  },
  data() {
    return {
      submitted: false,
      /* --- code --- */
      select_code: "",
      select_mode_code: "tags",
      /* --- 结构 --- */
      search_structure: [],
      select_mode_structure: "tags",
      /* --- 厚度 --- */
      thickness: {
        start: "",
        end: "",
      },
      /* --- 重量 --- */
      weight: {
        start: "",
        end: "",
      },
      /* --- 密度 --- */
      density: {
        start: "",
        end: "",
      },
      /* --- 曲隙率 --- */
      tortuous: {
        start: "",
        end: "",
      },
      /* --- 成本 --- */
      cost: {
        start: "",
        end: "",
      },
      /* --- 库存 --- */
      stock: {
        start: "",
        end: "",
      },
      keyword: "",
      searchVisiable: true,
      cols: 3,
      chartData: {},
      resetChart: false,
    };
  },
  methods: {
    inputNumberChange(data, type) {
      if (type == "thickness") {
        this.thickness.start = data[0];
        this.thickness.end = data[1];
      } else if (type == "weight") {
        this.weight.start = data[0];
        this.weight.end = data[1];
      } else if (type == "density") {
        this.density.start = data[0];
        this.density.end = data[1];
      } else if (type == "tortuous") {
        this.tortuous.start = data[0];
        this.tortuous.end = data[1];
      } else if (type == "cost") {
        this.cost.start = data[0];
        this.cost.end = data[1];
      } else if (type == "stock") {
        this.stock.start = data[0];
        this.stock.end = data[1];
      }
    },
    collapseClick() {
      this.searchVisiable = !this.searchVisiable;
      this.resetClick();
      this.$emit("update:highLevelSearch", !this.searchVisiable);
    },
    /* 切换多选框展示样式 */
    handlerSelect(type) {
      let selectType = `select_mode_${type}`;
      let selectData = `search_${type}`;
      if (this[selectData] && this[selectData].length >= 3) {
        this[selectType] = "multiple";
      } else {
        this[selectType] = "tags";
      }
    },
    addClick() {
      this.$router.push(`/fabric_detail/?type=add`);
    },
    updateParams() {
      let info = _.cloneDeep(this.chartData);
      if (this.select_code && this.select_code.length > 0) {
        info["code"] = this.select_code;
      }
      if (this.keyword && this.keyword.length > 0) {
        info["keyword"] = this.keyword;
      }
      // structure
      if (this.search_structure.length) {
        // info['structure'] = this.search_structure
        const tmp = JSON.stringify(this.search_structure);
        info["structure"] = tmp;
      }
      // thickness
      if (this.thickness.start && this.thickness.start.length > 0) {
        info["fabric_thickness_min"] = this.thickness.start;
      }
      if (this.thickness.end && this.thickness.end.length > 0) {
        info["fabric_thickness_max"] = this.thickness.end;
      }
      // weight
      if (this.weight.start && this.weight.start.length > 0) {
        info["fabric_weight_min"] = this.weight.start;
      }
      if (this.weight.end && this.weight.end.length > 0) {
        info["fabric_weight_max"] = this.weight.end;
      }
      // density
      if (this.density.start && this.density.start.length > 0) {
        info["density_min"] = this.density.start;
      }
      if (this.density.end && this.density.end.length > 0) {
        info["density_max"] = this.density.end;
      }
      // tortuous
      if (this.tortuous.start && this.tortuous.start.length > 0) {
        info["tortuous_gas_min"] = this.tortuous.start;
      }
      if (this.tortuous.end && this.tortuous.end.length > 0) {
        info["tortuous_gas_max"] = this.tortuous.end;
      }
      // cost
      if (this.cost.start && this.cost.start.length > 0) {
        info["price_min"] = this.cost.start;
      }
      if (this.cost.end && this.cost.end.length > 0) {
        info["price_max"] = this.cost.end;
      }
      // stock
      if (this.stock.start && this.stock.end.length > 0) {
        info["inventory_min"] = this.stock.start;
      }
      if (this.stock.end && this.stock.end.length > 0) {
        info["inventory_max"] = this.stock.end;
      }
      this.$emit("update:searchInfo", info);
    },
    searchClick() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$invalid && this.highLevelSearch && !this.select_code) {
        return;
      } else {
        this.resetChart = false;
        // 判断是否有拖动雷达图，切换展示表单选择框
        let chartVal = Object.values(this.chartData);
        let status = chartVal.filter((val) => {
          return val;
        });
        if (status.length) {
          this.$emit("update:showCheckbox", true);
        } else {
          this.$emit("update:showCheckbox", false);
        }
        this.updateParams();
      }
    },
    resetClick() {
      this.submitted = false;
      this.select_code = "";
      this.search_structure = [];
      this.thickness = {
        start: "",
        end: "",
      };
      if (this.$refs.thickness) {
        this.$refs.thickness.reset();
      }
      this.weight = {
        start: "",
        end: "",
      };
      if (this.$refs.weight) {
        this.$refs.weight.reset();
      }
      this.density = {
        start: "",
        end: "",
      };
      if (this.$refs.density) {
        this.$refs.density.reset();
      }
      this.tortuous = {
        start: "",
        end: "",
      };
      if (this.$refs.tortuous) {
        this.$refs.tortuous.reset();
      }
      this.cost = {
        start: "",
        end: "",
      };
      if (this.$refs.cost) {
        this.$refs.cost.reset();
      }
      this.stock = {
        start: "",
        end: "",
      };
      if (this.$refs.stock) {
        this.$refs.stock.reset();
      }
      if (this.$refs.searchChart) {
        this.$refs.searchChart.reset();
      }
      this.keyword = "";
      this.updateFunction();
    },
    updateFunction() {
      this.$emit("update:showCheckbox", false);
      this.updateParams();
    },
  },
};
</script>

<style scoped lang="scss"></style>

<style>
.form-label {
  margin-top: 10px;
}
</style>
