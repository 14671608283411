<template>
  <Layout>
    <PageHeader :title="PageHeaderT" :items="PageHeaderItems" />
    <div class="bg-white" style="overflow-x: auto;">
      <div class="card p-4" style="box-shadow: none;">
        <Search
            v-model:searchInfo="params"
            v-model:highLevelSearch="highLevelSearch"
            v-model:showCheckbox="showCheckbox"
            :handlerClickTable="handlerClickTable"
            :search_list="search_list"
            :chooseTableInfo="chooseTableInfo"
            @initFabric="initFabric"
        ></Search>
      </div>
      <div class="card mb-0" style="box-shadow: none;">
        <div>
          <Form
            :tableInfo="tableData"
            :table-loading="tableDataLoading"
            :highLevelSearch="highLevelSearch"
            :showCheckbox="showCheckbox"
            @handlerChooseChart="handlerChooseChart"
            @initFabric="initFabric"
          ></Form>
        </div>
        <Pagination
          v-if="pagination.total"
          v-model:page="pagination.currentPage"
          v-model:perPage="pagination.pageSize"
          :pages="pagination.pageOption"
          :prePageOption="pagination.pageSizeOption"
          :tab_info_length="pagination.total"
          class="me-5 my-3"
        >
        </Pagination>
      </div>
    </div>
  </Layout>
</template>

<script>
import Search from "./Search";
import Form from "./Form";
import { getFabricList } from "@/service";
import { tableMixin } from "@/mixins/Table1.js";
import _ from "loadsh";
export default {
  components: {
    Search,
    Form,
  },
  mixins: [tableMixin],
  data() {
    return {
      PageHeaderT: this.$t("fabric.title"),
      PageHeaderItems: [
        {
          text: this.$t("fabric.title"),
          active: true,
          href: "/fabric",
        },
      ],
      search_list: [],
      showCheckbox: false,
      highLevelSearch: false,
      handlerClickTable: false,
      chooseTableInfo: null,
    };
  },
  methods: {
    handlerChooseChart(info, status) {
      let chartInfo = _.cloneDeep(info);
      console.log(chartInfo);
      this.chooseTableInfo = _.merge({}, chartInfo.score);
      this.handlerClickTable = status;
    },
    initFabric() {
      this.getFirstPage();
    },
    requestList(params) {
      getFabricList(params)
        .then((res) => {
          if (res && res.payload && res.payload.data) {
            this.updateData(res.payload);
            this.search_list = res.payload.search_list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
.table-responsive {
  min-height: 60vh;
  max-height: 60vh;
}

.mask_model {
  width: 100vw;
  height: 100vh;
  background-color: rgba(47, 79, 79, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
</style>
