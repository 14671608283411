<template>
  <div class="form p-3" v-if="tableInfo.length">
    <!-- Bordered Tables -->
    <table class="table table-bordered table-nowrap">
      <thead class="table-light">
        <tr>
          <th scope="col" v-for="(head, index) in table_head" :key="index">
            {{ head }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(info, index) in tableInfo"
          :key="index"
          style="vertical-align: middle"
        >
          <th scope="row" v-if="highLevelSearch && showCheckbox">
            <div class="form-check d-flex align-items-center">
              <input
                class="form-check-input me-3 thCheckbox"
                type="checkbox"
                :id="info.id"
                @click="handlerChoose(info, index)"
              />
              <label class="form-check-label" for="activetableCheck01">{{
                info.number
              }}</label>
            </div>
          </th>
          <td scope="row" class="text-center" v-else>
            {{ info.number }}
          </td>
          <td>
            <a href="javascript:;" @click="handlerControl('view', info)">{{
              info.fabric_id
            }}</a>
          </td>
          <td class="text-center">
            <a href="javascript:;" @click="handleImgPreview(info)">
              <img
                :src="getPicture(info)"
                style="width: 6rem; height: 3rem; object-fit: contain"
                @error="
                  (e) => {
                    e.target.src = defaultImage;
                  }
                "
              />
            </a>
          </td>
          <td>{{ info.name }}</td>
          <td>{{ info.type }}</td>
          <td>{{ info.structure }}</td>
          <td>{{ info.fabric_thickness }}</td>
          <td>{{ info.fabric_weight }}</td>
          <td>{{ info.density }}</td>
          <td>{{ info.tortuous_gas }}</td>
          <td>{{ info.supplier_name }}</td>
          <td>{{ info.price }}</td>
          <td>{{ info.inventory }}</td>
          <td v-if="info.mmt && info.mmt.length">
            <a href="javascript:;" @click="toReport('mmt', info)">
              <!-- <div>{{$t('base.to_view')}}</div> -->
              <i class="ri-eye-fill"></i>
            </a>
          </td>
          <td v-else>-</td>
          <td v-if="info.ftt && info.ftt.length">
            <a href="javascript:;" @click="toReport('ftt', info)">
              <!-- <div>{{$t('base.to_view')}}</div> -->
              <i class="ri-eye-fill"></i>
            </a>
          </td>
          <td v-else>-</td>
          <td v-if="showOperation()">
            <div class="dropdown">
              <a
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="ri-more-2-fill"></i>
              </a>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li v-permit="'fabric.show'">
                  <div
                    class="dropdown-item cursor"
                    @click="handlerControl('view', info)"
                  >
                    {{ $t("base.to_view") }}
                  </div>
                </li>
                <li v-permit="'fabric.modify'" v-if="info['can_edit']">
                  <div
                    class="dropdown-item cursor"
                    @click="handlerControl('edit', info)"
                  >
                    {{ $t("base.edit") }}
                  </div>
                </li>
                <li v-permit="'fabric.delete'" v-if="info['can_edit']">
                  <div
                    class="dropdown-item cursor"
                    @click="handlerControl('delete', info)"
                  >
                    {{ $t("base.delete") }}
                  </div>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-else>
    <Empty v-if="!tableLoading"></Empty>
    <Loading v-else></Loading>
  </div>
  <message-alert
    :alertInfo="alertInfo"
    v-if="formValidataAlert"
  ></message-alert>
  <PreviewImg
    v-model:showPreviewImg="showPreviewImg"
    :src="src"
    v-if="showPreviewImg"
  ></PreviewImg>
</template>

<script>
import PreviewImg from "@/components/common/PreviewImg";
import defaultImage from "@/assets/image.png";
import { deleteFabricDetail } from "@/service/index";
import _ from "lodash";
import Swal from "sweetalert2";
export default {
  components: {
    PreviewImg,
  },
  props: {
    tableInfo: {
      type: Array,
      default: () => [],
    },
    tableLoading: {
      type: Boolean,
      default: false,
    },
    highLevelSearch: {
      type: Boolean,
      default: false,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["initFabric", "handlerChooseChart", "update:handlerClickTable"],
  data() {
    return {
      defaultImage,
      alertInfo: {
        variant: "danger",
        content: "",
        icon: "ri-error-warning-line",
      },
      formValidataAlert: false,
      showPreviewImg: false,
      src: "",
      currentChooseIndex: null,
    };
  },
  computed: {
    table_head() {
      let head = [];
      if (this.highLevelSearch && this.showCheckbox) {
        head = [
          "",
          this.$t("attribute.id"),
          this.$t("attribute.picture"),
          this.$t("attribute.name"),
          this.$t("attribute.type"),
          this.$t("fabric.structure"),
          this.$t("fabric.thickness"),
          this.$t("fabric.weight") + "(" + this.$t("fabric.weight_unit") + ")",
          this.$t("fabric.density") +
            "(" +
            this.$t("fabric.density_unit") +
            ")",
          this.$t("fabric.tortuous"),
          this.$t("attribute.supplier"),
          this.$t("attribute.cost") +
            "(" +
            this.$t("attribute.price_unit") +
            ")",
          this.$t("attribute.stock") +
            "(" +
            this.$t("attribute.stock_unit") +
            ")",
          "MMT",
          "FTT",
          this.$t("base.operation"),
        ];
      } else {
        head = [
          this.$t("base.No"),
          this.$t("attribute.id"),
          this.$t("attribute.picture"),
          this.$t("attribute.name"),
          this.$t("attribute.type"),
          this.$t("fabric.structure"),
          this.$t("fabric.thickness"),
          this.$t("fabric.weight") + "(" + this.$t("fabric.weight_unit") + ")",
          this.$t("fabric.density") +
            "(" +
            this.$t("fabric.density_unit") +
            ")",
          this.$t("fabric.tortuous"),
          this.$t("attribute.supplier"),
          this.$t("attribute.cost") +
            "(" +
            this.$t("attribute.price_unit") +
            ")",
          this.$t("attribute.stock") +
            "(" +
            this.$t("attribute.stock_unit") +
            ")",
          "MMT",
          "FTT",
          this.$t("base.operation"),
        ];
      }
      return head;
    },
  },
  created() {
    if (!this.showOperation()) {
      this.table_head.pop();
    }
  },
  methods: {
    handlerChoose(info, index) {
      this.currentChooseIndex = index;
      this.filterCheckBox();
      this.$emit("handlerChooseChart", info, true);
    },
    filterCheckBox() {
      let dom = document.querySelectorAll(".thCheckbox");
      if (dom) {
        dom.forEach((d, index) => {
          if (index == this.currentChooseIndex) {
            d.checked = true;
          } else {
            d.checked = false;
          }
        });
      }
    },
    handlerControl(type, info) {
      if (type == "delete") {
        Swal.fire({
          title: this.$t("base.delete_alert_title"),
          text: this.$t("base.delete_alert_info"),
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#f46a6a",
          confirmButtonColor: "#34c38f",
          confirmButtonText: this.$t("base.done"),
          cancelButtonText: this.$t("base.cancel"),
        }).then((result) => {
          if (result.isConfirmed) {
            deleteFabricDetail(info.id)
              .then((res) => {
                if (res) {
                  Swal.fire(
                    this.$t("base.success"),
                    this.$t("base.delete_alert_completed"),
                    "success"
                  );
                }
              })
              .then(() => {
                this.$emit("initFabric");
              })
              .catch((e) => {
                this.formValidataAlert = true;
                this.alertInfo = {
                  variant: "danger",
                  content: e.message,
                  icon: "ri-alert-line",
                };
                setTimeout(() => {
                  this.formValidataAlert = false;
                }, 4000);
              });
          }
        });
      } else {
        let url = this.$router.resolve({
          name: "fabric_detail",
          query: { id: info.id, type:type },
        });
        window.open(url.href, "_blank");
        // this.$router.push(`/fabric_detail/?id=${info.id}&type=${type}`);
      }
    },
    getPicture(info) {
      const pictures = _.get(info, "pictures", []);
      if (pictures == undefined || pictures.length < 1) {
        return "";
      }
      return pictures[0].url;
    },
    toReport(type, info) {
      const params = {
        type: type,
        id: info.id,
      };
      const routeUrl = this.$router.resolve({
        name: "report",
        params: params,
      });
      window.open(routeUrl.href, "_blank");
    },
    getMMT(info) {
      if (info.ftt_report_url) {
        return info.mmt_report_url;
      }
      return "";
    },
    getFTT(info) {
      if (info.ftt_report_url) {
        return info.ftt_report_url;
      }
      return "";
    },
    handleImgPreview(item) {
      this.src = this.getPicture(item);
      if (this.src == "") {
        return;
      }
      this.showPreviewImg = true;
    },
    showOperation() {
      const params = {
        list: ["fabric.show", "fabric.modify", "fabric.delete"],
        type: "some",
      };
      return this.$permit(params);
    },
  },
};
</script>

<style scoped lang="scss"></style>
